import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { ProfileImage } from '@src/views/Profile/Sidebar/ProfileImage';
import { useStores } from '@src/stores';
import { Name } from '@src/views/Profile/Sidebar/Name';
import { Title } from '@src/views/Profile/Sidebar/Title';
import { YearsOfExperience } from '@src/views/Profile/Sidebar/YearsOfExperience';
import { SidebarTagList } from '@src/views/Profile/Sidebar/SidebarSection/SidebarTagList';
import { Websites } from '@src/views/Profile/Sidebar/Websites';
import { Jobs } from '@src/views/Profile/Sidebar/Jobs';
import { Skills } from '@src/views/Profile/Sidebar/Skills';
import useToggle from '@src/hooks/useToggle';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import Location from '@src/views/Profile/Sidebar/Location';
import { Phone } from '@src/views/Profile/Sidebar/Phone';
import { Collaborators } from '@src/views/Profile/Sidebar/Collaborators';
import { InvitedBy } from '@src/views/Profile/Sidebar/InvitedBy';
import { AdminData } from '@src/views/Profile/Sidebar/AdminData';
import Profile from '@src/stores/Profile/Profile';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { throttle, uniqBy } from 'lodash';
import { ClientBuilderProfileLocation, ProfileLocation } from '@src/locations';
import { Breakpoints } from '@ateams/components';
import ExpertiseModal from '@src/components/Modal/ExpertiseModal';
import { useScreenClass } from 'react-grid-system';
import Roles from '@src/views/Profile/Sidebar/Roles';
import { Timezone } from './Timezone';
import { JobExperienceModal } from '@src/components/Modal/JobExperienceModal';
import { ExistingJob, Expertise, NewJob } from '@src/stores/Profile/models';
import { WorkingHours } from '@src/views/Profile/Sidebar/WorkingHours';
import MessageAndConnect from './MessageAndConnect';
import { toJS } from 'mobx';
import Portfolio from './Portfolio';
import ConnectionsSummary from './ConnectionsSummary';
import {
  getProjectMemberId,
  isNewExperienceItem,
} from '@src/views/Profile/helpers/experience';
import { ExperienceMemberRole } from '@a_team/models/dist/ExperienceObject';
import CVUploader from './CVUploader';
import RolesModal from '@src/views/Profile/Shared/RolesModal';
import SkillsModal from '@src/views/Profile/Shared/SkillsModal';
import UserBadges from '@src/components/UserBadges';
import { useQuery } from '@tanstack/react-query';
import { apiTeamGraph } from '@ateams/api';
import { queryKeys } from '@src/rq/keys';
import SuggestedRoles from './SuggestedRoles/SuggestedRoles';
import SuggestedSkills from './SuggestedSkills/SuggestedSkills';
import MutualConnections from './MutualConnections';
import SuggestedJobs from './SuggestedJobs';
import AvailabilityBannerV2 from '../Main/AvailabilityBannerV2';
import SuggestedIndustries from './SuggestedIndustries';
import { LinkedInUrl } from './LinkedInUrl';
import { GithubUrl } from './GithubUrl';
import SuggestedWebsite from './SuggestedWebsite/SuggestedWebsite';
import SuggestedPhoneNumber from './SuggestedPhoneNumber/SuggestedPhoneNumber';

interface Props {
  profile?: Profile;
  linkToProfile?: boolean;
  fixed?: boolean;
}

enum SuggestionComponentType {
  Skills = 'Skills',
  Roles = 'Roles',
  Jobs = 'Jobs',
  Industries = 'Industries',
  Website = 'Website',
  PhoneNumber = 'PhoneNumber',
}

const useStyles = createUseStyles<{
  fixed: Props['fixed'];
}>({
  wrapper: {
    background: '#fff',
    color: '#222',
    paddingTop: 120,
  },
  sidebar: {},
  mainDetails: {
    marginBottom: 24,
    textAlign: 'center',
    borderBottom: '1px solid rgba(128,128,128,0.3)',
    paddingBottom: 32,
    marginRight: 24,
    marginLeft: 24,
  },
  availabilityBanner: {
    margin: 24,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      width: 450,
      flex: '0 0 450px',
      paddingTop: 0,
    },
    sidebar: {
      width: 450,
      padding: 40,
      height: ({ fixed }) => (fixed ? '100vh' : '100%'),
      overflowY: 'auto',
      position: ({ fixed }) => (fixed ? 'fixed' : 'relative'),
    },
    mainDetails: {
      margin: 0,
      marginBottom: 24,
      paddingBottom: 0,
      borderBottom: 'none',
    },
  },
});

const AUGMENTED_BUILDER_MIN_CONFIDENCE = 0.8;

const Sidebar = (props: Props): ReactElement => {
  const analytics = useAnalytics();
  const screenClass = useScreenClass();
  const { users, auth, missions } = useStores();
  const { withWorkingHours } = auth;
  const styles = useStyles({
    fixed: props.fixed,
  });

  const [loading, setLoading] = useLoadingState();
  const [skillsModalOpen, toggleSkillsModalOpen] = useToggle();
  const [rolesModalOpen, toggleRolesModalOpen] = useToggle();
  const [mainRoleModalOpen, toggleMainRoleModalOpen] = useToggle();
  const [industryExperiencesOpen, toggleIndustryExperiencesOpen] = useToggle();
  const [jobToUpdate, setJobToUpdate] = useState<
    undefined | NewJob | ExistingJob
  >(undefined);
  const [jobsExperiencesOpen, toggleJobsExperiencesOpen] = useToggle();

  const uid = props.profile?.data.uid || users.profile?.data.uid;

  const augmentedBuilderResponseQuery = useQuery({
    enabled: !!uid && auth.isAdmin,
    queryKey: queryKeys.profile.experienceGraphAugmentedBuilder(
      uid || '',
      AUGMENTED_BUILDER_MIN_CONFIDENCE,
    ),
    queryFn: () => {
      if (!uid) {
        return Promise.resolve(undefined);
      }
      return apiTeamGraph.getExperienceGraphAugmentBuilder(auth, uid, {
        minConfidence: AUGMENTED_BUILDER_MIN_CONFIDENCE,
      });
    },
  });

  const mainAndAdditionalSkills = useMemo(() => {
    const profile = users.profile;
    const skills = profile?.skills;

    if (!skills) {
      return [];
    }

    return [...skills.main, ...skills.additional].sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  }, [users.profile?.skills.additional, users.profile?.skills.main]);

  const profile = useMemo(() => {
    return props.profile || users.profile;
  }, [props.profile, users.profile]);

  const disabled = useMemo(() => {
    return loading === true;
  }, [loading]);

  const showAvailabilityBanner = useMemo(() => {
    return profile?.showAvailabilityBanner && screenClass === 'xs';
  }, [profile?.showAvailabilityBanner, screenClass]);

  const handleSkillsClick = () => {
    toggleSkillsModalOpen();

    profile &&
      analytics.trackProfileSkillsEditClicked(
        profile.eventsSource,
        profile.application ? missions.currentMission?.data : undefined,
        profile.application?.currentRole,
        profile.application?.aid,
      );
  };

  const handleRolesClick = () => {
    toggleRolesModalOpen();
  };

  const trackWebsiteClick = (url: string) => {
    profile &&
      analytics.trackProfileWebsiteLinkClicked(
        profile.data.uid,
        profile.eventsSource,
        url,
        profile.application ? missions.currentMission?.data : undefined,
        profile.application?.currentRole,
        profile.application?.aid,
      );
  };

  const trackTalentIndustriesClick = () => {
    profile && analytics.trackProfileIndustriesAddClicked(profile.eventsSource);
  };

  const trackYearsExperience = () => {
    profile &&
      throttle(
        () =>
          analytics.trackProfileYearsExperienceChanged(
            profile.eventsSource,
            profile.data.yearsOfExperience || 0,
            missions.currentMission?.data,
            profile.application?.currentRole,
            profile.application?.aid,
          ),
        300,
      );
  };

  const handleTalentIndustriesClick = useCallback(() => {
    toggleIndustryExperiencesOpen();
    trackTalentIndustriesClick();
  }, []);

  const handleAdminDetailsClick = () => {
    auth.isAdmin && profile && setLoading(users.fetchAdminNotes(profile), null);
  };

  const onClickOnCopyDataClipboard = () => {
    if (!profile?.data.username) return;
    setLoading(
      users.getUserDataExport(profile?.data.username).then((result) => {
        navigator.clipboard.writeText(JSON.stringify(result));
        return 'Copied to clipboard';
      }),
    );
  };

  const onClickOnCopyApplicationDataClipboard = () => {
    if (!profile?.data.username || !profile?.application?.aid) return;
    setLoading(
      users
        .getUserApplicationDataExport(
          profile.application.aid,
          profile.data.username,
        )
        .then((result) => {
          navigator.clipboard.writeText(JSON.stringify(result));
          return 'Copied to clipboard';
        }),
    );
  };

  const onClickOnCopyProfileUrlClipboard = () => {
    if (!profile?.data.uid) return;
    setLoading(
      navigator.clipboard
        .writeText(ClientBuilderProfileLocation(profile.data.uid))
        .then(() => 'Copied to clipboard'),
    );
  };
  const onClickOnCopyEmailClipboard = () => {
    if (!profile?.data.uid) return;
    setLoading(
      navigator.clipboard
        .writeText(profile.data.email || '')
        .then(() => 'Copied to clipboard'),
    );
  };

  const onJobClickUpdate = useCallback((job: NewJob | ExistingJob) => {
    setJobToUpdate(job);
    toggleJobsExperiencesOpen();
  }, []);

  const onJobAdd = useCallback(() => {
    setJobToUpdate(undefined);
    toggleJobsExperiencesOpen(true);
  }, []);

  const verifiedSkills = useMemo(() => {
    return profile?.allSkills.filter((s) => s.verifiedSkill === true) || [];
  }, [profile?.allSkills]);

  const requiredSkills = useMemo(() => {
    return (
      profile?.application?.currentRole?.requiredSkills?.filter(
        (s) =>
          profile.application?.currentRole?.requiredSkills?.find(
            (rs) => rs.talentSkillId === s.talentSkillId,
          ) !== undefined,
      ) || []
    ).map(
      (rs) =>
        ({
          id: rs.talentSkillId,
          name: rs.talentSkillName,
          rating:
            profile?.allSkills?.find((s) => s.id === rs.talentSkillId)
              ?.rating || 0,
          featured: true,
          categoryIds: [],
        } as Expertise),
    );
  }, [profile?.allSkills]);

  const preferredSkills = useMemo(() => {
    return (
      profile?.application?.currentRole?.preferredSkills?.filter(
        (s) =>
          profile.application?.currentRole?.preferredSkills?.find(
            (rs) => rs.talentSkillId === s.talentSkillId,
          ) !== undefined,
      ) || []
    ).map(
      (rs) =>
        ({
          id: rs.talentSkillId,
          name: rs.talentSkillName,
          rating:
            profile?.allSkills?.find((s) => s.id === rs.talentSkillId)
              ?.rating || 0,
          featured: true,
          categoryIds: [],
        } as Expertise),
    );
  }, [profile?.allSkills]);

  const otherSkills = useMemo(() => {
    return (
      profile?.allSkills.filter(
        (s) =>
          profile.application?.currentRole?.requiredSkills?.find(
            (rs) => rs.talentSkillId === s.id,
          ) === undefined &&
          profile.application?.currentRole?.preferredSkills?.find(
            (rs) => rs.talentSkillId === s.id,
          ) === undefined &&
          s.verifiedSkill !== true,
      ) || []
    );
  }, [profile?.allSkills]);

  const isJobOwner = useMemo(() => {
    if (!jobToUpdate || isNewExperienceItem(jobToUpdate)) return true;
    return (
      jobToUpdate?.members.find(
        (member) => getProjectMemberId(member) === auth.uid,
      )?.experienceRole === ExperienceMemberRole.Owner
    );
  }, [jobToUpdate]);

  const preferredWithInferredSkills = useMemo(() => {
    if (!preferredSkills.length) return [];
    const inferredSkillsPreferredInRole =
      augmentedBuilderResponseQuery.data?.suggestedProfile.skills.filter(
        (s) =>
          profile?.application?.currentRole?.preferredSkills?.findIndex(
            (ps) => ps.talentSkillId === s.id,
          ) !== -1 &&
          preferredSkills.findIndex((ps) => !!ps.rating && ps.id === s.id) ===
            -1,
      ) || [];
    return uniqBy(
      [
        ...preferredSkills.filter(
          (s) =>
            inferredSkillsPreferredInRole.findIndex((is) => is.id === s.id) ===
            -1,
        ),
        ...inferredSkillsPreferredInRole,
      ],
      (s) => s.id,
    );
  }, [
    augmentedBuilderResponseQuery.data?.suggestedProfile.skills,
    preferredSkills,
  ]);

  const filteredInferredSkills = useMemo(
    () =>
      augmentedBuilderResponseQuery.data?.suggestedProfile.skills.filter(
        (s) =>
          (preferredWithInferredSkills || []).find((ps) => ps.id === s.id) ===
          undefined,
      ),
    [
      preferredWithInferredSkills,
      augmentedBuilderResponseQuery.data?.suggestedProfile.skills,
    ],
  );

  const renderSuggestions = (type: SuggestionComponentType) => {
    if (!profile || !profile.readonly) {
      return null;
    }

    const adminOnly =
      auth.isAdmin && auth.currentUser?.uid !== profile?.data.uid;
    const shouldRender =
      auth.isAdmin || auth.currentUser?.uid === profile?.data.uid;
    const userId = adminOnly ? profile?.data.uid : auth.currentUser?.uid;

    if (!userId) {
      return null;
    }

    if (!shouldRender) {
      return null;
    }

    switch (type) {
      case SuggestionComponentType.Skills:
        return <SuggestedSkills profile={profile} adminOnly={adminOnly} />;
      case SuggestionComponentType.Roles:
        return (
          <SuggestedRoles
            userId={userId}
            profile={profile}
            adminOnly={adminOnly}
          />
        );
      case SuggestionComponentType.Jobs:
        return (
          <SuggestedJobs
            userId={userId}
            adminOnly={adminOnly}
            onAccept={onJobClickUpdate}
          />
        );
      case SuggestionComponentType.Industries:
        return (
          <SuggestedIndustries
            userId={userId}
            profile={profile}
            adminOnly={adminOnly}
          />
        );
      case SuggestionComponentType.Website:
        return (
          <SuggestedWebsite
            userId={userId}
            profile={profile}
            adminOnly={adminOnly}
          />
        );
      case SuggestionComponentType.PhoneNumber:
        return (
          <SuggestedPhoneNumber
            userId={userId}
            profile={profile}
            adminOnly={adminOnly}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.wrapper}>
      {profile && (
        <>
          <SkillsModal
            saveRightAway={profile.application ? true : false}
            profile={profile}
            onClose={toggleSkillsModalOpen}
            open={skillsModalOpen}
          />
          <RolesModal
            profile={profile}
            onClose={toggleRolesModalOpen}
            open={rolesModalOpen}
          />
          <ExpertiseModal
            title={'Select your main Role'}
            onSubmit={(roles) => {
              profile?.setMainRole(roles[0]);
              toggleMainRoleModalOpen(false);
            }}
            selectedItems={profile?.mainRole ? [profile?.mainRole] : []}
            open={mainRoleModalOpen}
            onSearch={(query) => profile?.queryRoles(query, false, false)}
            onClose={toggleMainRoleModalOpen}
            displayType={'tags'}
            singleSelection
            required
          />
          <ExpertiseModal
            title={'Select your industries'}
            selectedItems={profile?.industryExperiences}
            onSubmit={(industries) => {
              profile?.setIndustryExperiences(industries);
              toggleIndustryExperiencesOpen(false);
            }}
            onSearch={(query) => profile?.queryIndustries(query)}
            open={industryExperiencesOpen}
            onClose={toggleIndustryExperiencesOpen}
            required
            displayType={'tags'}
          />
          <JobExperienceModal
            title={
              jobToUpdate && !jobToUpdate.isSuggested
                ? 'Update your job experience'
                : 'Create a new job experience'
            }
            isSuggestedJob={jobToUpdate?.isSuggested}
            isOwner={isJobOwner}
            mainAndAdditionalSkills={mainAndAdditionalSkills}
            selectedItems={profile?.industryExperiences}
            onSubmit={(
              company,
              title,
              skills,
              specialization,
              startDate,
              endDate,
            ) => {
              profile?.addCompanyJob(
                company,
                title,
                skills,
                specialization,
                startDate,
                endDate,
                jobToUpdate,
              );
              toggleJobsExperiencesOpen(false);
            }}
            open={jobsExperiencesOpen}
            onClose={toggleJobsExperiencesOpen}
            required
            displayType={'tags'}
            jobToUpdate={jobToUpdate}
          />
        </>
      )}
      <div className={styles.sidebar}>
        <div className={styles.mainDetails}>
          {profile && auth.isAdmin && (
            <AdminData
              auth={auth}
              profile={profile?.data}
              application={profile?.application}
              handleDetails={handleAdminDetailsClick}
              onClickOnCopyDataClipboard={onClickOnCopyDataClipboard}
              onClickOnCopyApplicationDataClipboard={
                onClickOnCopyApplicationDataClipboard
              }
              onClickOnCopyProfileUrlClipboard={
                onClickOnCopyProfileUrlClipboard
              }
              onClickOnCopyEmailClipboard={onClickOnCopyEmailClipboard}
            />
          )}

          {profile &&
            (props.linkToProfile ? (
              <a
                href={`/${profile.data.username}`}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <ProfileImage
                  url={
                    !profile?.isCurrentUser && !!profile?.application
                      ? ProfileLocation(profile.data.username)
                      : undefined
                  }
                  src={profile.data.profilePictureURL}
                  readonly={profile.readonly}
                  onChange={(url) => profile?.updateProfileImage(url)}
                  disabled={disabled}
                  badges={profile.data.badges}
                  communityBadges={profile.data.communityBadges}
                />
              </a>
            ) : (
              <ProfileImage
                url={
                  !profile?.isCurrentUser && !!profile?.application
                    ? ProfileLocation(profile.data.username)
                    : undefined
                }
                src={profile.data.profilePictureURL}
                readonly={profile.readonly}
                onChange={(url) => profile?.updateProfileImage(url)}
                disabled={disabled}
                badges={profile.data.badges}
                communityBadges={profile.data.communityBadges}
              />
            ))}

          {profile?.data.fullName && <Name name={profile?.data.fullName} />}
          {profile && (
            <Title
              roleId={profile?.mainRole?.id}
              roleTitle={profile?.mainRole?.name}
              readonly={profile.readonly}
              onEditClick={toggleMainRoleModalOpen}
              disabled={disabled}
            />
          )}

          {profile && (
            <div style={{ marginTop: 16, marginBottom: 8 }}>
              <UserBadges
                badges={profile.data.badges}
                communityBadges={profile.data.communityBadges}
                expandOnHover
              />
            </div>
          )}
          {profile?.showYears && (
            <YearsOfExperience
              readonly={profile?.readonly}
              years={profile.yearsOfExperience}
              onChange={(years) => {
                profile.setYearsOfExperience(years);
                trackYearsExperience();
              }}
              disabled={disabled}
              error={profile?.showErrors && profile.hasYearsExperienceError}
            />
          )}
        </div>
        {showAvailabilityBanner && profile?.availabilityType && (
          <AvailabilityBannerV2
            type={profile?.availabilityType}
            fromDate={profile.availabilityFromDate}
            hours={profile?.availabilityHours}
            lastUpdate={profile?.lastAvailabilityUpdate}
            className={styles.availabilityBanner}
            notes={profile?.availabilityNotes}
          />
        )}
        {!users.isCurrentUser && profile && profile.data?.verified && (
          <MessageAndConnect />
        )}

        {profile &&
          profile.data?.verified &&
          !profile.application &&
          profile.readonly && <ConnectionsSummary profile={profile} />}

        {profile && !users.isCurrentUser && (
          <MutualConnections profile={profile} auth={auth} />
        )}

        {profile?.showLocation && (
          <Location
            auth={auth}
            profile={profile}
            readonly={profile.readonly}
            city={profile.city}
            province={profile.province}
            country={profile.country}
            onChange={profile.setLocation}
            disabled={disabled}
            description={
              !profile.readonly
                ? `This is the location where you'll be physically located while working on missions. Misrepresenting your location is a violation of our terms and could lead to removal.`
                : undefined
            }
          />
        )}
        {profile?.showTimezone && (
          <Timezone
            readonly
            timezone={profile.timezone}
            onChange={profile.setTimezone}
            disabled={disabled}
          />
        )}
        {profile?.showWorkingHours &&
          !profile?.application &&
          withWorkingHours && (
            <WorkingHours
              readonly={profile.readonly}
              workingHours={toJS(profile.workingHours)}
              defaultTimezone={profile.timezone}
              onChange={profile.setWorkingHours}
              disabled={disabled}
              isClearable={false}
            />
          )}
        {profile?.showPhone && (
          <Phone
            onChange={profile.setPhone}
            phone={profile.phone || ''}
            disabled={disabled}
            readonly={profile.readonly}
          />
        )}
        {renderSuggestions(SuggestionComponentType.PhoneNumber)}
        {profile?.userCV && !auth.restrictedAccess && (
          <CVUploader
            cv={profile.userCV}
            canView={Boolean(profile.isCurrentUser || auth.isAdmin)}
            canUpload={Boolean(profile.isCurrentUser)}
            onRemove={profile.removeUserCv}
            onUpload={profile.updateUserCv}
          />
        )}
        {profile?.showPortfolio && (
          <Portfolio
            readonly={profile.readonly}
            portfolio={profile.portfolio}
            onChange={profile.setPortfolio}
          />
        )}

        {profile?.showWebsites && (
          <Websites
            websites={profile?.websites}
            onLinkClick={trackWebsiteClick}
            onAdd={profile?.addEmptyWebsite}
            onChange={profile?.setWebsites}
            readonly={profile.readonly}
            disabled={disabled}
          />
        )}
        {renderSuggestions(SuggestionComponentType.Website)}
        {profile && !profile.readonly && !profile.application && (
          <LinkedInUrl
            existingUrl={profile.data.linkedIn?.profileUrl}
            onChange={profile.setLinkedinUsername}
          />
        )}

        {profile && !profile.readonly && !profile.application && (
          <GithubUrl
            isSoftwareEngineer={profile.isSoftwareEngineer}
            existingUrl={profile.data.github?.profileUrl}
            onChange={profile.setGithubUsername}
          />
        )}

        {profile?.showRoles && (
          <Roles
            roles={profile?.roles}
            onAddClick={handleRolesClick}
            readonly={profile?.readonly}
            error={profile?.showErrors && profile.hasRolesError}
            disabled={disabled}
            withEditableTags={false}
            onRemove={profile?.setAdditionalRoles}
          />
        )}
        {renderSuggestions(SuggestionComponentType.Roles)}
        {auth.isAdmin &&
        profile?.showRoles &&
        augmentedBuilderResponseQuery.data?.suggestedProfile.specializations
          .length ? (
          <Roles
            label={'Inferred roles'}
            roles={
              augmentedBuilderResponseQuery.data?.suggestedProfile
                .specializations
            }
            readonly={true}
            error={profile?.showErrors && profile.hasRolesError}
            disabled={disabled}
          />
        ) : null}
        {profile?.showSkills &&
          (auth.isAdmin ? (
            <>
              {requiredSkills.length > 0 ? (
                <Skills
                  title="Required Skills"
                  forceShowAll
                  skills={requiredSkills}
                  isAdmin={true}
                  onChange={handleSkillsClick}
                  readonly={profile?.readonly}
                  disabled={disabled}
                  showColorByRating={true}
                  onRemove={profile?.updateSkills}
                />
              ) : null}
              {preferredSkills.length > 0 ? (
                <Skills
                  title="Preferred Skills"
                  forceShowAll
                  skills={preferredWithInferredSkills}
                  isAdmin={true}
                  onChange={handleSkillsClick}
                  readonly={profile?.readonly}
                  disabled={disabled}
                  showColorByRating={true}
                  onRemove={profile?.updateSkills}
                />
              ) : null}
              {verifiedSkills.length > 0 ? (
                <Skills
                  title="Verified Skills"
                  forceShowAll
                  skills={verifiedSkills}
                  isAdmin={true}
                  onChange={handleSkillsClick}
                  readonly={profile?.readonly}
                  disabled={disabled}
                  showColorByRating={false}
                  onRemove={profile?.updateSkills}
                />
              ) : null}
              {otherSkills.length > 0 ? (
                <Skills
                  title={verifiedSkills.length ? 'Other Skills' : 'Skills'}
                  forceShowAll
                  skills={otherSkills}
                  isAdmin={true}
                  onChange={handleSkillsClick}
                  readonly={profile?.readonly}
                  disabled={disabled}
                  onRemove={profile?.updateSkills}
                />
              ) : null}
              {filteredInferredSkills?.length ? (
                <Skills
                  title={'Inferred skills'}
                  skills={filteredInferredSkills}
                  isAdmin={true}
                  readonly={true}
                />
              ) : null}
            </>
          ) : (
            <Skills
              title="Skills"
              skills={profile?.mainSkills}
              isAdmin={false}
              onChange={handleSkillsClick}
              readonly={profile?.readonly}
              disabled={disabled}
              onRemove={profile?.updateSkills}
            />
          ))}
        {renderSuggestions(SuggestionComponentType.Skills)}
        {profile?.showIndustries && (
          <SidebarTagList
            title={'Industries'}
            items={profile?.industryExperienceTags}
            onAddClick={handleTalentIndustriesClick}
            readonly={profile?.readonly}
            onChange={(tags) =>
              profile?.setIndustryExperiences(
                profile?.industryExperiences.filter((i) =>
                  tags.includes(i.name),
                ),
              )
            }
          />
        )}
        {renderSuggestions(SuggestionComponentType.Industries)}
        {profile?.showJobs && (
          <Jobs
            jobs={profile.jobs || []}
            ownerJobsOnly={false}
            onChange={profile.setJobs}
            onAdd={onJobAdd}
            readonly={profile.readonly}
            disabled={disabled}
            defaultRole={profile?.data.roleCategory?.title}
            currentUserId={profile?.data.uid}
            onClickUpdate={onJobClickUpdate}
          />
        )}

        {auth.withNewJobs !== undefined &&
          !auth.withNewJobs &&
          renderSuggestions(SuggestionComponentType.Jobs)}

        {profile?.showCollaborators && (
          <Collaborators
            collaborators={profile.data.frequentCollaborators}
            clickable={auth.basicAccess}
          />
        )}
        {!!profile?.data.invitedBy && (
          <InvitedBy
            user={profile.data.invitedBy}
            clickable={auth.basicAccess}
          />
        )}
      </div>
      <LoadingIndicator loading={loading} />
    </div>
  );
};

export default observer(Sidebar);
