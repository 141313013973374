import React, { CSSProperties, ReactElement } from 'react';
import Tag from '../Tag';
import { TagList } from '../Tag/TagList';
import { ColorName } from '../theme/colors';

export interface SelectableTagListProps {
  selectedItems: string[];
  items: string[];
  format?: (item: string) => string;
  onChange: (item: string, selected: boolean) => void;
  color?: ColorName;
  selectedColor?: ColorName;
  className?: string;
  tagStyles?: CSSProperties;
  square?: boolean;
  disabled?: boolean;
  limit?: number;
}

export const SelectableTagList = (
  props: SelectableTagListProps,
): ReactElement => {
  const {
    items,
    selectedItems,
    onChange,
    format,
    color = 'regular',
    selectedColor = 'secondaryDark',
    className,
    disabled,
    limit,
    tagStyles,
    square,
  } = props;

  const upToLimit = selectedItems.length === limit;

  return (
    <TagList style={{ flexWrap: 'wrap' }} className={className}>
      {items.map((item) => {
        const selected = selectedItems.includes(item);
        return (
          <Tag
            key={item}
            square={square}
            style={{
              marginBottom: 8,
              marginLeft: 0,
              marginRight: 8,
              cursor: 'pointer',
              ...((disabled || (upToLimit && !selected)) && {
                cursor: 'default',
                pointerEvents: 'none',
                opacity: 0.5,
              }),
              ...tagStyles,
            }}
            color={selected ? selectedColor : color}
            onClick={() => onChange(item, !selected)}
          >
            {format ? format(item) : item}
          </Tag>
        );
      })}
    </TagList>
  );
};
