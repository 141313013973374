import React, { useState } from 'react';
import { Button } from '@a_team/ui-components';
import { observer } from 'mobx-react';
import Step, { SetupProps, StepStatus } from '../common/Step';
import StepIcon, { StepIconType } from '../common/Step/StepIcon';
import { OnboardingStage } from '@a_team/models/dist/UserObject';
import { useRequestProfileReview } from '@src/rq/users';
import ConfirmFirstSubmissionModal from '@src/views/Profile/Main/ConfirmFirstSubmissionModal';
import { useStores } from '@src/stores';
import { useAnalytics } from '@ateams/analytics/dist/platform';

interface ReviewProfileStepProps {
  onboardingStage: OnboardingStage;
}

const ReviewProfileStep = ({ onboardingStage }: ReviewProfileStepProps) => {
  const [showConfirmSubmitEdits, setShowConfirmSubmitEdits] = useState(false);
  const analytics = useAnalytics();
  const { mutate: requestProfileReview, isLoading } = useRequestProfileReview();
  const { auth } = useStores();

  const handleSubmitProfile = () => {
    requestProfileReview(undefined, {
      onSuccess() {
        if (auth.uid) {
          analytics.trackProfileSubmittedForReview(auth.uid);
        }
        setShowConfirmSubmitEdits(false);
        auth.handleProfileCompleteness(auth, {
          ...(auth?.user?.profileCompleteness ?? {}),
          dismissed: true,
        });
      },
    });
  };

  const INCOMPLETE_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.NUMBER} value="2" />,
    title: 'Submit your profile for review',
    description:
      'Our team will review your profile and ensure your background aligns with the needs of A.Team clients.',
    completionTime: 'Profile review can last up to 2 weeks',
    cta: (
      <Button
        size="md"
        onClick={() => {
          setShowConfirmSubmitEdits(true);
        }}
        disabled={isLoading}
      >
        {isLoading ? 'Submitting...' : 'Submit profile'}
      </Button>
    ),
    videoUrl: 'https://www.loom.com/share/f9c60c3024a4427caea315e06112c693',
    stepStatus:
      onboardingStage === OnboardingStage.PendingReviewRequest
        ? StepStatus.IN_PROGRESS
        : StepStatus.INCOMPLETE,
  };

  const IN_PROGRESS_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.IN_PROGRESS} />,
    title: 'Your profile is in review',
    description:
      'A member of our team will review your profile and reach out via email once complete.',
    completionTime: 'Estimated time to review: up to 2 weeks',
    cta: <div>In progress</div>,
    videoUrl: 'https://www.loom.com/share/eebde8df2311498c85605d8b0328f371',
    stepStatus: StepStatus.IN_PROGRESS,
  };

  const REJECTED_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.COMPLETED} />,
    title: 'Profile review complete',
    description:
      'A member of our team has reviewed your profile and requested more information.',
    cta: <div>Completed</div>,
    stepStatus: StepStatus.COMPLETED,
  };

  const COMPLETED_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.COMPLETED} />,
    title: 'Profile review complete',
    description:
      'A member of our team has reviewed your profile and invited you to an evaluation interview.',
    cta: <div>Completed</div>,
    stepStatus: StepStatus.COMPLETED,
  };

  const getPayload = () => {
    if (
      [
        OnboardingStage.IncompleteProfile,
        OnboardingStage.PendingReviewRequest,
      ].includes(onboardingStage)
    ) {
      return INCOMPLETE_DATA;
    }

    if (onboardingStage === OnboardingStage.ReviewRequested) {
      return IN_PROGRESS_DATA;
    }

    if (
      onboardingStage === OnboardingStage.PendingSecondReview ||
      onboardingStage === OnboardingStage.SecondReviewRequested
    ) {
      return REJECTED_DATA;
    }

    return COMPLETED_DATA;
  };

  const stepPayload = getPayload();

  const renderConfirmationModal = () => {
    if (!showConfirmSubmitEdits) {
      return null;
    }

    return (
      <ConfirmFirstSubmissionModal
        open={showConfirmSubmitEdits}
        onClose={() => setShowConfirmSubmitEdits(false)}
        onConfirm={handleSubmitProfile}
      />
    );
  };

  return (
    <>
      <Step {...stepPayload} />
      {renderConfirmationModal()}
    </>
  );
};

export default observer(ReviewProfileStep);
