import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  ProposalData,
  ProposalDataCurrency,
  ProposalId,
  ProposalObject,
  ProposalUpdateData,
  SampleProposalObject,
} from '@a_team/models/dist/ProposalObject';
import { MissionId } from '@a_team/models/dist/MissionObject';
import { BasicUserObject, UserId } from '@a_team/models/dist/UserObject';
import { TalentSkillId } from '@a_team/models/dist/TalentCategories';
import { TalentIndustryId } from '@a_team/models/dist/TalentIndustry';
import { ProposalTfsPitch } from '@a_team/models/dist/ProposalObject';
import { AdminPitch } from '@a_team/models/src/AdminNotesObject';
import { DateISOString } from '@a_team/models/src/misc';
import { MinimalAccountObject } from '@a_team/models/src/Account';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';

export const BasePath = '/proposals';

enum ProposalCandidateRecommendation {
  Recommended = 'Recommended',
  Alternative = 'Alternative',
}

export interface TfsPitchesResponse {
  tfsPitches: Array<BuilderTfsPitchDescriptor>;
  adminPitch?: AdminPitch;
}

export interface MissionProposalsResponse {
  account?: MinimalAccountObject;
  proposalURLs: ProposalObject['publicURL'][];
  records?: {
    pid: string;
    clientName?: string;
    projectName?: string;
    createdBy: string;
    publicURL: string;
    applications: string[];
    candidates?: {
      aid: string;
      userId: string;
      hourlyRate?: number;
      isTeamLead?: boolean;
      recommendation?: ProposalCandidateRecommendation;
      roleCategoryId?: string;
      tfsPitch?: ProposalTfsPitch; // Might be missing from legacy proposals
      includeCustomQuestionReply?: boolean;
    }[];
    isShared?: boolean;
    sharedAt?: string;
    sharedBy?: string;
    createdAt: string;
    currency: ProposalDataCurrency;
    version?: number;
  }[];
  users?: BasicUserObject[];
  roles?: { rid: string; title: string }[];
}

/**
 * Information about a `tfsPitch` and its parent proposal context.
 *
 * _We need these `BuilderTfsPitchDescriptor`s when we want to show history of blurbs / tfsPitches_
 */
export interface BuilderTfsPitchDescriptor {
  createdAt: DateISOString;
  tfsPitch: ProposalTfsPitch;
  clientName?: string;
  projectName?: string;
  hourlyRate?: number;
  role: { rid: string; title: string };
  uid: UserId;
  proposalId: ProposalId;
}

export interface SampleRoleBuilderData {
  uid: UserId;
  hourlyRate?: number;
  blurb?: string;
  gptUsageLogId?: string;
  skills?: TalentSkillId[];
  roleCategory?: RoleCategoryObject;
  reviews?: string[];
}

export interface SampleRoleData {
  title: string;
  requiredSkills?: TalentSkillId[];
  preferredSkills?: TalentSkillId[];
  requiredIndustries?: TalentIndustryId[];
  preferredIndustries?: TalentIndustryId[];
  builders: SampleRoleBuilderData[];
}

// exported functions

export default class ProposalsEndpoint extends ServiceEndpoint {
  // public query(auth: ServiceAuth): Promise<QueryResult<ProposalBasicObject>> {
  //   return this.fetch(auth, BasePath + '/query');
  // }

  public create(
    auth: ServiceAuth,
    data: ProposalData,
  ): Promise<ProposalObject> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }

  public update(
    auth: ServiceAuth,
    pid: string,
    data: ProposalUpdateData,
  ): Promise<ProposalObject> {
    return this.fetch(auth, `${BasePath}/${pid}`, null, 'PATCH', data);
  }

  // public getProposalById(
  //   auth: ServiceAuth,
  //   pid: ProposalId,
  // ): Promise<ProposalObject> {
  //   return this.fetch(auth, BasePath + `/proposal/${pid}`);
  // }

  public getProposalsForMission(
    auth: ServiceAuth,
    mid: MissionId,
  ): Promise<MissionProposalsResponse> {
    return this.fetch(auth, BasePath + `/missions/${mid}`);
  }

  public createSampleProposal(
    auth: ServiceAuth,
    name: string,
    roles: SampleRoleData[],
    clientName?: string,
    hubspotId?: string,
  ): Promise<SampleProposalObject> {
    return this.fetch(auth, BasePath + `/sample`, null, 'post', {
      name,
      roles,
      clientName,
      hubspotId,
    });
  }

  public getBuilderTfsPitches(
    auth: ServiceAuth,
    userId: UserId,
  ): Promise<TfsPitchesResponse> {
    return this.fetch(auth, BasePath + '/tfs-pitches', { userId }, 'get');
  }
}
