import React, { ReactElement, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { find } from 'lodash';
import { UserUsername } from '@a_team/models/dist/UserObject';
import { TargeterSearchTab } from '@a_team/models/dist/TargeterSearchObject';
import { Colors } from '@ateams/components';
import { TargeterSearchCache } from '@src/stores/TeamGraph/TargeterTabManager';
import TargeterUserCard from '@src/views/SkillTargeter/TargeterUserCardView';
import {
  TalentSkill,
  TalentSkillRating,
} from '@a_team/models/dist/TalentCategories';
import { useTalentSkills } from '@src/rq/useTalentSkills';
import { Blurb } from '@src/views/SkillTargeter/TargeterUserCardView/Blurb';
import { Skills } from '@src/views/SkillTargeter/TargeterUserCardView/Skills';
import { HourlyRate } from '@src/views/SkillTargeter/TargeterUserCardView/HourlyRate';
import { apiUsers } from '@ateams/api';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import { useMutation } from '@tanstack/react-query';

interface Props {
  editSelectedBuilders: (
    tabLabel: string,
    selectedBuilders: TargeterSearchTab['selectedBuilders'],
  ) => void;
  tabs: TargeterSearchCache[];
  hideRateInProposal: boolean;
  duplicateBuilders: Map<UserUsername, boolean>;
}

export interface TalentSkillWithRating extends TalentSkill {
  rating?: TalentSkillRating;
}

const useStyles = createUseStyles({
  noResultsContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noResultsMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    minHeight: '10em',
  },
  roleDelimiter: {
    display: 'flex',
    width: '100%',
    maxWidth: 625,
    marginLeft: '3em',
    alignItems: 'center',
  },
  hline: {
    background: Colors.regular,
    flexGrow: 50,
    height: 2,
    marginLeft: '1em',
  },
});

export const generateSkillNameWithRating = (
  name: string,
  rating: TalentSkillRating,
) => `${name} ${rating ? ` | ${rating}` : ''}`;

export const extractSkillName = (name: string) => name.split('|')[0].trim();

const TargeterTeamCardList = (props: Props): ReactElement => {
  const styles = useStyles();
  const { data: allSkills } = useTalentSkills({});
  const { editSelectedBuilders, duplicateBuilders, hideRateInProposal, tabs } =
    props;

  const tabResults = useMemo(() => {
    return tabs.filter(({ selectedBuilders }) => selectedBuilders.length);
  }, [tabs]);

  const { auth } = useStores();
  const [loading, setLoading] = useLoadingState(false);

  const setRate = (label: string, user: UserUsername, rate: string) => {
    const hourlyRate = Number(rate);
    if (!Number.isNaN(hourlyRate)) {
      editSelectedBuilders(
        label,
        find(tabs, (tab) => tab.label === label)?.selectedBuilders.map(
          (selection) =>
            selection.user.username === user
              ? { ...selection, hourlyRate }
              : selection,
        ) ?? [],
      );
    }
  };

  const setReviews = (label: string, user: UserUsername, reviews: string[]) => {
    editSelectedBuilders(
      label,
      find(tabs, (tab) => tab.label === label)?.selectedBuilders.map(
        (selection) =>
          selection.user.username === user
            ? { ...selection, reviews }
            : selection,
      ) ?? [],
    );
  };

  const setBlurb = (label: string, user: UserUsername, blurb: string) => {
    const userData = find(
      tabs,
      (tab) => tab.label === label,
    )?.selectedBuilders.find((builder) => builder.user.username === user);

    if (userData) {
      userData.blurb = blurb;
    }

    editSelectedBuilders(
      label,
      find(tabs, (tab) => tab.label === label)?.selectedBuilders.map(
        (selection) =>
          selection.user.username === user ? userData || selection : selection,
      ) ?? [],
    );
  };

  const setGptUsageLogId = (
    label: string,
    user: UserUsername,
    gptUsageLogId: string,
  ) => {
    const userData = find(
      tabs,
      (tab) => tab.label === label,
    )?.selectedBuilders.find((builder) => builder.user.username === user);

    if (userData) {
      userData.gptUsageLogId = gptUsageLogId;
    }

    editSelectedBuilders(
      label,
      find(tabs, (tab) => tab.label === label)?.selectedBuilders.map(
        (selection) =>
          selection.user.username === user ? userData || selection : selection,
      ) ?? [],
    );
  };

  const generateBlurbMutation = useMutation({
    mutationFn: (data: {
      username: string;
      roleName: string;
      skills: string[];
    }) =>
      apiUsers.generateUserBlurbForSampleProposal(
        auth,
        data.username,
        data.roleName,
        data.skills,
      ),
  });

  const onClickOnGenerateBlurb = useCallback(
    (
      tab: string,
      username: UserUsername,
      selectedSkills: string[],
      callback: (text: string) => void,
    ) => {
      setLoading(
        generateBlurbMutation
          .mutateAsync({ username, roleName: tab, skills: selectedSkills })
          .then(({ text, gptUsageLogId }): string => {
            callback(text);
            setGptUsageLogId(tab, username, gptUsageLogId);
            return 'Blurb generated';
          })
          .catch(() => {
            return Promise.reject('Error generating blurb');
          }),
      );
    },
    [setBlurb],
  );

  const setSelectedSkills = (
    label: string,
    user: UserUsername,
    skill: string,
    isSelected: boolean,
  ) => {
    const userData = find(
      tabs,
      (tab) => tab.label === label,
    )?.selectedBuilders.find((builder) => builder.user.username === user);

    const skillData = allSkills?.find(
      (generalSkill) => generalSkill.name === extractSkillName(skill),
    );

    if (userData && !userData?.skills) {
      userData.skills = [];
    }

    if (isSelected && skillData) {
      userData?.skills?.push(skillData.id);
    } else {
      if (userData) {
        userData.skills = userData?.skills?.filter((userSkill) => {
          return userSkill !== skillData?.id;
        });
      }
    }

    editSelectedBuilders(
      label,
      find(tabs, (tab) => tab.label === label)?.selectedBuilders.map(
        (selection) =>
          selection.user.username === user ? userData || selection : selection,
      ) ?? [],
    );
  };

  const deselectBuilder = (label: string, username: UserUsername) => {
    editSelectedBuilders(
      label,
      find(tabs, (tab) => tab.label === label)?.selectedBuilders.filter(
        ({ user }) => user.username !== username,
      ) ?? [],
    );
  };

  if (tabResults.length === 0) {
    return (
      <div className={styles.noResultsContainer}>
        <div className={styles.noResultsMessage}>
          <span style={{ fontSize: 56 }}>💔</span>
          <span>
            <strong>Our builders need love...</strong>
          </span>
          <span>
            Please select some builders and come back to the team page.
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      {tabResults.map(({ label, selectedBuilders }, index) => {
        return (
          <div key={index.toString()}>
            <div className={styles.roleDelimiter}>
              <div>{label}</div>
              <div className={styles.hline} />
            </div>
            <ul>
              {Object.values(selectedBuilders).map(
                ({ user, hourlyRate, skills, blurb }) => {
                  const allUserSkills: TalentSkillWithRating[] = [
                    ...(user.talentProfile?.talentSkills.mainTalentSkills ||
                      []),
                    ...(user.talentProfile?.talentSkills
                      .additionalTalentSkills || []),
                  ]
                    .map((userSkill) => {
                      const skillData = allSkills?.find(
                        (skill) => skill.id === userSkill.talentSkillId,
                      );
                      return {
                        ...skillData,
                        rating: userSkill.rating,
                      };
                    })
                    .filter(
                      (skill) => !!skill && !!skill.name,
                    ) as TalentSkillWithRating[];
                  const selectedSkills = skills
                    ?.map((userSkill) =>
                      allUserSkills?.find((skill) => skill.id === userSkill),
                    )
                    .map((skill) =>
                      skill
                        ? generateSkillNameWithRating(
                            skill.name,
                            skill.rating || TalentSkillRating.None,
                          )
                        : '',
                    );

                  return (
                    <TargeterUserCard
                      selected
                      showDetails={false}
                      duplicated={duplicateBuilders.get(user.username)}
                      key={`${label}-${user.uid}`}
                      user={user}
                      showRecommendations
                      onRecommendationsChange={(reviews) =>
                        setReviews(label, user.username, reviews)
                      }
                      selectBuilder={() =>
                        deselectBuilder(label, user.username)
                      }
                      hourlyRateComponent={
                        <HourlyRate
                          hideRate={hideRateInProposal}
                          hourlyRate={hourlyRate}
                          setRate={(rate: string) =>
                            setRate(label, user.username, rate)
                          }
                        />
                      }
                      blurbComponent={
                        <Blurb
                          onChange={(val) =>
                            setBlurb(label, user.username, val)
                          }
                          onClickOnGenerateBlurb={(callback) => {
                            onClickOnGenerateBlurb(
                              label,
                              user.username,
                              selectedSkills || [],
                              callback,
                            );
                          }}
                        />
                      }
                      skillsComponent={
                        <Skills
                          selectedSkills={selectedSkills || []}
                          allSkills={allUserSkills || []}
                          onChange={(val, selected) =>
                            setSelectedSkills(
                              label,
                              user.username,
                              val,
                              selected,
                            )
                          }
                        />
                      }
                    />
                  );
                },
              )}
            </ul>
          </div>
        );
      })}
      <LoadingIndicator loading={loading} />
    </>
  );
};

export default observer(TargeterTeamCardList);
