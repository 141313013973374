import { FeatureFlagNames } from '@a_team/models/dist/FeatureFlag';
import { UserId } from '@a_team/models/dist/UserObject';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const usersKeys = createQueryKeys('users', {
  usersByIds: (uids: UserId[]) => ({
    uids,
  }),
  evaluateFeatureFlag: (uid: string, featureFlag: FeatureFlagNames) => ({
    uid,
    featureFlag,
  }),
});
