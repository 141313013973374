import React, { useMemo } from 'react';
import { Button } from '@a_team/ui-components';
import { observer } from 'mobx-react';
import Step, { SetupProps, StepStatus } from '../common/Step';
import StepIcon, { StepIconType } from '../common/Step/StepIcon';
import { OnboardingStage } from '@a_team/models/dist/UserObject';
import {
  useGetEvaluationCallDetails,
  useRequestEvaluationCall,
} from '@src/rq/users';
import { format } from 'date-fns';

interface EvaluationCallStepProps {
  onboardingStage: OnboardingStage;
}

const EvaluationCallStep = ({ onboardingStage }: EvaluationCallStepProps) => {
  const stepNumber = [
    OnboardingStage.PendingSecondReview,
    OnboardingStage.SecondReviewRequested,
  ].includes(onboardingStage)
    ? '4'
    : '3';
  const { mutate: requestEvaluationCall, isLoading } =
    useRequestEvaluationCall();
  const { data: callDetails, isLoading: isLoadingCallDetails } =
    useGetEvaluationCallDetails();

  const handleRequestEvaluationCall = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    requestEvaluationCall();
  };

  const interviewDate = useMemo(() => {
    return callDetails?.date ? new Date(callDetails.date) : new Date();
  }, [isLoadingCallDetails, callDetails?.date]);

  const INCOMPLETE_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.NUMBER} value={stepNumber} />,
    title: 'Showcase your expertise on an evaluation call',
    description:
      'Schedule a call with our team to share details about your skills and past experience.',
    completionTime: 'Evaluation interview duration: 30-45 minutes',
    cta: (
      <Button
        size="md"
        onClick={handleRequestEvaluationCall}
        disabled={isLoading}
      >
        {isLoading ? 'Please wait...' : 'Schedule call'}
      </Button>
    ),
    videoUrl: 'https://www.loom.com/share/7bce27f2e8674de29d890b4e4f4792e2',
    stepStatus:
      onboardingStage === OnboardingStage.PendingInterview
        ? StepStatus.IN_PROGRESS
        : StepStatus.INCOMPLETE,
  };

  const IN_PROGRESS_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.IN_PROGRESS} />,
    title: 'Your evaluation call has been scheduled',
    description: (
      <div>
        Join the call{' '}
        <a href={callDetails?.url} target="_blank" rel="noreferrer">
          here
        </a>{' '}
        on{' '}
        {callDetails?.date &&
          format(interviewDate, `MMMM d, yyyy 'at' h:mm a zzz`)}
        . After joining the call you'll receive an update in 2-5 business days.
      </div>
    ),
    completionTime: `Evaluation interview duration: 30-45 minutes`,
    cta: <div>Scheduled</div>,
    videoUrl: 'https://www.loom.com/share/51d678033a0a4ad4a123f8cb59b80e78',
    stepStatus: StepStatus.IN_PROGRESS,
  };

  const COMPLETED_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.COMPLETED} />,
    title: 'Profile review complete',
    description:
      'A member of our team has reviewed your profile and invited you to an evaluation interview.',
    cta: <div>Completed</div>,
    stepStatus: StepStatus.COMPLETED,
  };

  const getPayload = () => {
    if (
      [
        OnboardingStage.IncompleteProfile,
        OnboardingStage.PendingReviewRequest,
        OnboardingStage.ReviewRequested,
        OnboardingStage.PendingSecondReview,
        OnboardingStage.SecondReviewRequested,
        OnboardingStage.PendingInterview,
      ].includes(onboardingStage)
    ) {
      return INCOMPLETE_DATA;
    }

    if (onboardingStage === OnboardingStage.InterviewScheduled) {
      return IN_PROGRESS_DATA;
    }

    return COMPLETED_DATA;
  };

  const stepPayload = getPayload();

  return <Step {...stepPayload} />;
};

export default observer(EvaluationCallStep);
