import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints, IconType, Icon, Colors } from '@ateams/components';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';
import { useHistory } from 'react-router-dom';
import useToggle from '@src/hooks/useToggle';
import { Flags } from '@src/stores/Auth';
import { SettingsAccountLocation, SignOutLocation } from '@src/locations';
import AccountSettingsModal from '@src/components/Modal/AccountSettingsModal';
import ABFlagsModal from '@src/components/Modal/ABFlagsModal';
import NavMenuButton from '@src/views/Profile/TopBar/NavMenuButton';
import Dropdown, { DropdownItem } from '@src/components/Dropdown';
import { UserStatus } from '@a_team/models/dist/UserObject';
import { ProfileTooltip } from '@src/views/Profile/ProfileTooltip';
import { observer } from 'mobx-react';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import { apiUsers } from '@ateams/api';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import PaymentDetailsModal from '@src/components/Modal/PaymentDetailsModal';
import { Button, Icon as UiIcon } from '@a_team/ui-components';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { useEnhanceProfileWithPolling } from '@src/rq/profileSuggestions';

const IS_SELECTION_TEAM_OPTION_HIDDEN = true;

const useStyles = createUseStyles({
  dropdown: {
    position: 'absolute',
    zIndex: 1000,
    right: 0,
    top: '100%',
    maxHeight: '80vh',
  },
  actionsWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
    padding: '10px 15px 0 15px',
  },
  enhanceBtn: {
    height: '40px',
    alignSelf: 'center',
    marginRight: '0px !important',
    marginTop: '0px !important',
    color: `${Colors.secondaryDark} !important`,
    borderColor: `${Colors.secondaryDark} !important`,
  },
  enhanceIcon: {
    height: 16,
    width: 16,
    marginRight: '12px',
    color: Colors.secondaryDark,
  },
  icon: {
    '& svg, & span': {
      cursor: 'pointer !important',
      display: 'flex',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    actionsWrapper: {
      paddingTop: 0,
      justifyContent: 'flex-end',
    },
  },
});

const ProfileNavBar = () => {
  const styles = useStyles();
  const analytics = useAnalytics();
  const { users, auth, vetters, payments } = useStores();
  const { profile } = users;
  const history = useHistory();
  const [loading, setLoading] = useLoadingState();
  const [menuOpen, toggleMenuOpen] = useToggle();
  const [deleteOpen, toggleDeleteOpen] = useToggle();
  const [selectionTeamOpen, toggleSelectionTeamOpen] = useToggle();
  const [accountSettingsOpen, toggleAccountSettingOpen] = useToggle();
  const [flagsModalsOpen, toggleFlagsModalOpen] = useToggle();
  const [paymentMethodOpen, setPaymentMethodOpen] = useState(false);

  const { suggestions, handleEnhanceProfile, initiateSuggestionsPolling } =
    useEnhanceProfileWithPolling();

  useEffect(() => {
    if (auth?.currentUser?.isSuggestionsGenerationInProgress) {
      initiateSuggestionsPolling();
    }
  }, [auth?.currentUser?.isSuggestionsGenerationInProgress]);

  const handleLoginAs = (): void => {
    if (!profile) return;
    setLoading(
      auth.loginAs(profile.data.username).then((): void => {
        window.location.reload();
      }),
    );
  };

  const handleSubmit = () => {
    const cta = profile?.cta;
    cta && setLoading(cta?.callback(), cta.confirmation || null);
  };

  const handleDelete = (): void => {
    if (!profile) return;

    toggleDeleteOpen(false);

    setLoading(
      apiUsers.deleteUser(auth, profile.data.username).then((): void => {
        if (profile.data.username === auth.uid) {
          auth.token = null;
        }

        window.location.assign('/');
      }),
    );
  };

  const handleSelectionTeam = (): void => {
    if (!profile) return;

    toggleSelectionTeamOpen(false);

    if (profile.data.isVetter) {
      setLoading(
        vetters.removeFromSelectionTeam(profile.data.uid),
        'User removed from SelectionTeam',
      );
      return;
    }

    setLoading(
      vetters.addToSelectionTeam(profile.data.uid),
      'User added to SelectionTeam',
    );
  };

  const handleClickPaymentMethod = () =>
    setLoading(
      payments.getSetupDetails().then(() => setPaymentMethodOpen(true)),
      null,
    );

  const onAbFlagsSubmit = useCallback(
    (flags: Flags) => {
      if (!profile) return Promise.reject();
      return auth.assignUserAbFlags(auth, profile?.data.username, flags);
    },
    [auth, profile?.data.username],
  );

  const onABFlagsClose = useCallback(() => {
    toggleFlagsModalOpen(false);
  }, []);

  const showDropdown =
    users.isCurrentUser || auth.isAdmin || auth.canSwitchUser;

  const enhanceProfileButton = useMemo(() => {
    // Only available to the logged in current user
    if (!profile?.isCurrentUser) {
      return null;
    }

    if (auth.restrictedAccess) {
      return null;
    }

    return (
      <TooltipWrapped
        arrow
        theme="light"
        animation="fade"
        disabled={
          users.isEnhancingProfile ||
          !suggestions?.unenhanceableProfileReasons.length
        }
        html={
          <div style={{ textAlign: 'left', fontSize: 12 }}>
            {suggestions?.unenhanceableProfileReasons[0]}
          </div>
        }
      >
        <Button
          variant="ghost"
          className={styles.enhanceBtn}
          disabled={
            users.isEnhancingProfile ||
            !!suggestions?.unenhanceableProfileReasons.length
          }
          onClick={handleEnhanceProfile}
        >
          <Icon
            type={
              users.isEnhancingProfile
                ? IconType.CircleLoadingViolet
                : IconType.SuggestedTeams
            }
            rotating={users.isEnhancingProfile}
            className={styles.enhanceIcon}
          />
          Enhance my profile
        </Button>
      </TooltipWrapped>
    );
  }, [
    users.isEnhancingProfile,
    suggestions?.unenhanceableProfileReasons,
    auth.restrictedAccess,
  ]);

  return (
    <>
      {auth.user && profile && (
        <>
          <AccountSettingsModal
            profile={auth.user}
            onClose={toggleAccountSettingOpen}
            open={accountSettingsOpen}
          />
          <ABFlagsModal
            onClose={onABFlagsClose}
            onSubmit={onAbFlagsSubmit}
            open={flagsModalsOpen}
          />
        </>
      )}

      {payments.setupDetails && (
        <PaymentDetailsModal
          iframeUrl={payments.setupDetails.setupIFrameURL}
          open={paymentMethodOpen}
          onClose={() => setPaymentMethodOpen(false)}
        />
      )}

      <ConfirmModal
        title="Delete this User"
        description={`Are you sure you want to permanently delete ${profile?.data.fullName}?`}
        onConfirm={handleDelete}
        open={deleteOpen}
        onClose={toggleDeleteOpen}
        actionLabel="Delete Now"
        actionColor="danger"
      />
      <ConfirmModal
        title={
          profile?.data.isVetter
            ? 'Remove user from SelectionTeam'
            : 'Add user to SelectionTeam'
        }
        description={`Are you sure you want to ${
          profile?.data.isVetter ? 'remove' : 'add'
        } ${profile?.data.fullName} ${
          profile?.data.isVetter ? 'from' : 'to'
        } the SelectionTeam? ${
          !profile?.data.isVetter
            ? 'An email notification will be sent to the user.'
            : ''
        }`}
        onConfirm={handleSelectionTeam}
        open={selectionTeamOpen}
        onClose={toggleSelectionTeamOpen}
        actionLabel={profile?.data.isVetter ? 'Remove' : 'Add'}
        actionColor={profile?.data.isVetter ? 'danger' : 'success'}
      />

      <div className={styles.actionsWrapper} style={{}}>
        {enhanceProfileButton}
        {showDropdown && (
          <div style={{ position: 'relative' }}>
            <NavMenuButton
              open={menuOpen}
              onClick={() => {
                toggleMenuOpen();
                profile &&
                  analytics.trackProfileOptionsMenuClicked(profile.data.uid);
              }}
            >
              <span data-testing-id={undefined}>
                <UiIcon className={styles.icon} name="filter" size="md" />
              </span>
            </NavMenuButton>
            <Dropdown
              open={menuOpen}
              onClose={() => toggleMenuOpen(false)}
              className={styles.dropdown}
            >
              {auth.canSwitchUser &&
                profile?.data.uid !== auth.uid &&
                profile?.data.status === UserStatus.Active && (
                  <DropdownItem
                    danger
                    onClick={() => {
                      handleLoginAs();
                      toggleMenuOpen();
                    }}
                  >
                    Login as {profile?.data.firstName}
                  </DropdownItem>
                )}
              {auth.isAdmin && (
                <>
                  {!IS_SELECTION_TEAM_OPTION_HIDDEN && (
                    <DropdownItem danger onClick={toggleSelectionTeamOpen}>
                      {profile?.data.isVetter
                        ? 'Remove from SelectionTeam'
                        : 'Add to SelectionTeam'}
                    </DropdownItem>
                  )}
                  <DropdownItem
                    danger
                    onClick={() => {
                      toggleFlagsModalOpen();
                      toggleMenuOpen();
                    }}
                  >
                    Change User AB flags
                  </DropdownItem>
                  <DropdownItem
                    danger
                    onClick={() => {
                      toggleDeleteOpen();
                      toggleMenuOpen();
                    }}
                  >
                    Delete User
                  </DropdownItem>
                </>
              )}
              {users.isCurrentUser && (
                <>
                  <DropdownItem
                    onClick={() => {
                      history.push(SettingsAccountLocation);
                    }}
                  >
                    Settings
                  </DropdownItem>
                  {!!auth.user?.activeMissions.length && (
                    <DropdownItem onClick={handleClickPaymentMethod}>
                      Payment method
                    </DropdownItem>
                  )}
                  <DropdownItem
                    onClick={() => {
                      history.push(SignOutLocation);
                    }}
                  >
                    Log Out
                  </DropdownItem>
                </>
              )}
            </Dropdown>
          </div>
        )}
        <div>
          {!!profile?.cta && !auth.readonlyAccess && (
            <ProfileTooltip
              disabled={
                !profile?.cta?.tooltipErrors ||
                profile?.cta?.tooltipErrors.length === 0
              }
              position={'bottom'}
              html={
                <div style={{ paddingTop: 8 }}>
                  Please fill the following required fields:
                  <ul style={{ textAlign: 'left', marginTop: 8 }}>
                    {profile?.cta?.tooltipErrors?.map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                </div>
              }
            >
              {profile?.cta?.label === 'Edit' ? (
                <Button
                  onClick={handleSubmit}
                  disabled={profile.cta?.disabled}
                  variant="ghost"
                  style={{
                    border: '1px solid #DADADC',
                    height: 40,
                    width: 40,
                    padding: 0,
                  }}
                >
                  <UiIcon className={styles.icon} name="edit" size="md" />
                </Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  className={styles.icon}
                  disabled={profile.cta?.disabled}
                  variant="main"
                  style={{
                    paddingRight: 12,
                    paddingLeft: 12,
                    height: 40,
                  }}
                >
                  {profile.cta.label === 'Save' ? (
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 10,
                      }}
                    >
                      <UiIcon
                        className={styles.icon}
                        name="edit"
                        size="md"
                        color="Grey@100"
                      />
                      {profile?.cta?.label}
                    </span>
                  ) : (
                    profile.cta.label
                  )}
                </Button>
              )}
            </ProfileTooltip>
          )}
        </div>
      </div>
      <LoadingIndicator loading={loading} />
    </>
  );
};

export default observer(ProfileNavBar);
