import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { SampleProposalObject } from '@a_team/models/dist/ProposalObject';
import { TargeterSearchTab } from '@a_team/models/dist/TargeterSearchObject';
import { UserUsername } from '@a_team/models/dist/UserObject';
import {
  Button as CallToActionButton,
  Checkbox,
  Colors,
  TextColors,
} from '@ateams/components';
import { SampleRoleData } from '@ateams/api/dist/endpoints/Proposals';
import { apiProposals } from '@src/logic/services/endpoints';
import { useStores } from '@src/stores';
import { TargeterSearchCache } from '@src/stores/TeamGraph/TargeterTabManager';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import StickyStrip from '@src/components/StickyStrip';
import TextButton from '@src/components/TextButton';
import TargeterTeamCardList from '@src/views/SkillTargeter/TeamView/TargeterTeamCardList';
import { HubspotIdModal } from '@src/views/SkillTargeter/TeamView/HubspotIdModal';
import { Icon } from '@a_team/ui-components';
import { copyToClipboard } from '@ateams/react-utils/dist/helpers/clipboard';

const BANNER_TTL_MS = 30e3;

const getRequirements = (
  url: string,
): {
  requiredSkills?: string[];
  preferredSkills?: string[];
  requiredIndustries?: string[];
  preferredIndustries?: string[];
} => {
  const requiredSkills = [
    ...url.matchAll(/requiredSkills\[\]=([a-z\d]{24})/gi),
  ].map(([_, id]) => id);

  const preferredSkills = [
    ...url.matchAll(/preferredSkills\[\]=([a-z\d]{24})/gi),
  ].map(([_, id]) => id);

  const requiredIndustries = [
    ...url.matchAll(/requiredIndustries\[\]=([a-z\d]{24})/gi),
  ].map(([_, id]) => id);

  const preferredIndustries = [
    ...url.matchAll(/preferredIndustries\[\]=([a-z\d]{24})/gi),
  ].map(([_, id]) => id);

  return {
    requiredSkills,
    preferredSkills,
    requiredIndustries,
    preferredIndustries,
  };
};

const getSampleRoleData = (
  tabs: TargeterSearchCache[],
  hideRates: boolean,
): SampleRoleData[] => {
  return tabs
    .filter(({ label, selectedBuilders: users }) => label && users.length > 0)
    .map(({ label: title, selectedBuilders, url }): SampleRoleData => {
      const roleRequirements = getRequirements(url);
      const builders = selectedBuilders.map(
        ({ user, hourlyRate, blurb, skills, gptUsageLogId, reviews }) => ({
          uid: user.uid,
          hourlyRate: hideRates ? undefined : hourlyRate,
          blurb,
          skills,
          gptUsageLogId,
          reviews,
        }),
      );

      return { title, builders, ...roleRequirements };
    });
};

const findDuplicateUsers = (
  tabs: TargeterSearchCache[],
): Map<UserUsername, boolean> => {
  const alreadySelected = new Map<UserUsername, boolean>([]);
  const duplicateBuilders: UserUsername[] = [];

  tabs.forEach(({ selectedBuilders }) => {
    selectedBuilders.forEach(({ user: { username } }) => {
      const builderAlreadySelected = alreadySelected.get(username);
      alreadySelected.set(username, true);

      builderAlreadySelected && duplicateBuilders.push(username);
    });
  });

  return new Map<UserUsername, boolean>(
    duplicateBuilders.map((username) => [username, true]),
  );
};

interface Props {
  editSelectedBuilders: (
    tabLabel: string,
    selectedBuilders: TargeterSearchTab['selectedBuilders'],
  ) => void;
  tabs: TargeterSearchCache[];
  toggleTeamView: () => void;
}

const useStyles = createUseStyles({
  tabBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderBottom: `1px solid ${Colors.regular}`,
    marginTop: '1.5em',
    padding: 10,
  },
  textButton: {
    color: TextColors.primaryLight,
    marginRight: '1.5em',
    width: 'auto',
    cursor: 'pointer',
  },
  content: {
    background: `#F7F7F7`,
    display: 'flex',
    flex: 1,
    height: '100%',
  },
  results: {
    paddingLeft: '3em',
    padding: '1em',
    paddingBottom: '3em',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  clientNameInputContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'baseline',
  },
  clientNameInput: {
    margin: '1em 0',
    minWidth: 400,
    maxWidth: '25%',
    flexGrow: 1,
    '& > div': {
      padding: '0.6em 0.5em',
      minHeight: 'unset',
    },
  },
  proposalButton: {
    cursor: 'pointer',
    margin: '1em',
    width: 'auto',
  },
  disabled: {
    cursor: 'not-allowed',
  },
  countMessage: {
    marginBottom: '2em',
    marginTop: '2em',
  },
  generatedBanner: {
    width: 595,
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    background: 'rgba(240, 255, 221, 0.50)',
    border: `1px solid ${Colors.success}`,
  },
});

const ProposalBanner = ({ onClose }: { onClose: () => void }): ReactElement => {
  return (
    <StickyStrip onClose={onClose}>
      <div>
        <strong>Your sample proposal is being generated!</strong> 🎉
        <br />
        It may take a few minutes for the sample proposal to go live, please
        wait.
      </div>
    </StickyStrip>
  );
};

const GeneratedBanner = ({
  clientAppUrl,
}: {
  clientAppUrl: string;
}): ReactElement => {
  const styles = useStyles();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 4000);
      return (): void => clearTimeout(timeout);
    }
    return;
  }, [copied]);

  return (
    <div className={styles.generatedBanner}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <Icon name={'statusPositiveNoBorder'} color={'Green@600'} size={'md'} />
        Sample Proposal Generated
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
        <a
          className={styles.textButton}
          style={{
            margin: 0,
            paddingRight: 8,
            borderRight: '1px solid #D2D6DD',
          }}
          href={clientAppUrl}
          target="_blank"
          rel="noreferrer"
        >
          View client app proposal
        </a>{' '}
        <a
          className={styles.textButton}
          style={{ marginRight: 0 }}
          href={'#!'}
          onClick={() => {
            copyToClipboard(clientAppUrl);
            setCopied(true);
          }}
        >
          {copied ? 'Copied' : 'Copy URL'}
        </a>
      </div>
    </div>
  );
};

const TargeterTeamView = (props: Props): ReactElement => {
  const { editSelectedBuilders, tabs, toggleTeamView } = props;
  const { auth } = useStores();
  const styles = useStyles();

  const [proposalUrl, setProposalUrl] = useState('');
  const [clientAppUrl, setClientAppUrl] = useState('');
  const [ratesHidden, setRatesHidden] = useState(true);
  const [clientName, setClientName] = useState('');
  const [generating, setGenerating] = useState(false);
  const [isHubspotModalOpen, setIsHubspotModalOpen] = useState(false);
  const [loading, setLoading] = useLoadingState();
  const [bannerTimeout, setBannerTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  useEffect(() => {
    if (proposalUrl || clientAppUrl) {
      const timeout = setTimeout(() => setBannerTimeout(null), BANNER_TTL_MS);
      setBannerTimeout(timeout);
    } else if (bannerTimeout) {
      clearTimeout(bannerTimeout);
      setBannerTimeout(null);
    }
  }, [proposalUrl, clientAppUrl]);

  const { selectedBuilderCount, duplicateUsers } = useMemo(() => {
    const duplicateUsers = findDuplicateUsers(tabs);
    const selectedBuilderCount = tabs.reduce(
      (count, { selectedBuilders }) => count + selectedBuilders.length,
      0,
    );

    return { selectedBuilderCount, duplicateUsers };
  }, [tabs]);

  const generateProposal = (
    hideRates: boolean,
    client?: string,
    hubspotId?: string,
  ) => {
    setProposalUrl('');
    setClientAppUrl('');
    setIsHubspotModalOpen(false);
    setGenerating(true);

    const hexDate = Date.now().toString(16);
    const builders: SampleRoleData[] = getSampleRoleData(tabs, hideRates);

    setLoading(
      apiProposals
        .createSampleProposal(auth, hexDate, builders, client, hubspotId)
        .then(({ publicURL, clientAppURL }: SampleProposalObject) => {
          setProposalUrl(publicURL);
          clientAppURL && setClientAppUrl(clientAppURL);
          setGenerating(false);
        }),
      null,
    );
  };

  const cannotGenerate =
    generating || !![...duplicateUsers.keys()].length || !clientName;

  return (
    <>
      <div className={styles.tabBar}>
        <TextButton className={styles.textButton} onClick={toggleTeamView}>
          Back to search
        </TextButton>
      </div>

      {bannerTimeout && (
        <ProposalBanner onClose={() => setBannerTimeout(null)} />
      )}

      <HubspotIdModal
        onClose={() => setIsHubspotModalOpen(false)}
        onConfirm={(hubspotId) =>
          generateProposal(ratesHidden, clientName, hubspotId)
        }
        open={isHubspotModalOpen}
      />

      <div className={styles.content}>
        <div className={styles.results}>
          {clientAppUrl && <GeneratedBanner clientAppUrl={clientAppUrl} />}
          <div className={cx(styles.clientNameInputContainer)}>
            <OutlinedInput
              value={clientName || ''}
              onChange={(e) => setClientName(e.target.value)}
              placeholder="Client name in proposal..."
              className={styles.clientNameInput}
            />
            <CallToActionButton
              size="small"
              squared
              disabled={cannotGenerate}
              onClick={() => {
                setIsHubspotModalOpen(true);
              }}
              className={cx(
                styles.proposalButton,
                cannotGenerate && styles.disabled,
              )}
            >
              Generate proposal
            </CallToActionButton>
          </div>

          <Checkbox
            label="Hide builder rates in proposal"
            onChange={(e) => setRatesHidden(e.target.checked)}
            checked={ratesHidden}
            margin="none"
          />

          <span className={styles.countMessage}>
            {selectedBuilderCount === 1
              ? `One builder is selected.`
              : `There are ${selectedBuilderCount} selected builders.`}
          </span>

          <TargeterTeamCardList
            editSelectedBuilders={editSelectedBuilders}
            duplicateBuilders={duplicateUsers}
            hideRateInProposal={ratesHidden}
            tabs={tabs}
          />
        </div>
      </div>
      <LoadingIndicator loading={loading} />
    </>
  );
};

export default observer(TargeterTeamView);
